<template>
  <b-card
    no-body
    class="mt-1"
    style="z-index:9"
  >
    <b-card-header class="card-header email-detail-head d-flex flex-wrap">
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar
          class="mr-75 mb-25 mb-md-0"
          size="48"
          variant="light-primary"
          style="background-color: var(--primary)"
          text="S"
          :src="message.createdBy ? message.createdBy.avatar : ''"
        />

        <div class="mail-items  text-md-left">
          <p class="h5 mb-0">
            {{ message.createdBy ? `${message.createdBy.firstName} ${message.createdBy.lastName}` : message.fromMail }}
            <!--            <b-button v-if="showDelete"-->
            <!--                      size="sm"-->
            <!--                      variant="flat-danger"-->
            <!--                      @click="$emit('remove')"-->
            <!--            >-->
            <!--              <sw-icon icon="TrashIcon" />-->
            <!--            </b-button>-->
          </p>

          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="email-info-dropup"
          >
            <template #button-content>
              <span class="font-small-3 text-muted mr-25">{{ message.fromMail | textIsland(24) }}</span>

              <feather-icon
                icon="ChevronDownIcon"
                size="10"
              />
            </template>

            <table class="table table-sm table-borderless font-small-3">
              <tbody>
                <tr>
                  <td class="text-right text-muted">
                    {{ $t('From') }}:
                  </td>

                  <td>
                    {{ message.fromMail | textIsland(isMobile ? 24 : 999) }}
                  </td>

                  <td class="text-right text-muted">
                    <b-button
                      v-b-tooltip.hover.v-success
                      size="sm"
                      variant="flat-success"
                      class="p-md-25 d-inline-block btn-icon"
                      :title="$t('Copy')"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        @click="() => { copy(message.fromMail) }"
                      />
                    </b-button>
                  </td>
                </tr>

                <tr
                  v-for="(address, i) in message.toAddresses"
                  :key="`to_address_${i}`"
                >
                  <td class="text-right text-muted">
                    {{ $t('To') }}:
                  </td>

                  <td>
                    {{ address.mail | textIsland(isMobile ? 24 : 999) }}
                  </td>

                  <td class="text-right text-muted">
                    <b-button
                      v-b-tooltip.hover.v-success
                      size="sm"
                      variant="flat-success"
                      class="p-md-25 d-inline-block btn-icon"
                      :title="$t('Copy')"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        @click="() => { copy(address.mail) }"
                      />
                    </b-button>
                  </td>
                </tr>

                <tr
                  v-for="(address, i) in message.bccAddresses"
                  :key="`bcc_addresses_${i}`"
                >
                  <td class="text-right text-muted">
                    {{ $t('BCC') }}:
                  </td>

                  <td>
                    {{ address.mail | textIsland(isMobile ? 24 : 999) }}
                  </td>

                  <td class="text-right text-muted">
                    <b-button
                      v-b-tooltip.hover.v-success
                      size="sm"
                      variant="flat-success"
                      class="p-md-25 d-inline-block btn-icon"
                      :title="$t('Copy')"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        @click="() => { copy(address.mail) }"
                      />
                    </b-button>
                  </td>
                </tr>

                <tr
                  v-for="(address, i) in message.ccAddresses"
                  :key="`cc_addresses_${i}`"
                >
                  <td class="text-right text-muted">
                    {{ $t('CC') }}:
                  </td>
                  <td>
                    {{ address.mail | textIsland(isMobile ? 24 : 999) }}
                  </td>
                  <td class="text-right text-muted">
                    <b-button
                      v-b-tooltip.hover.v-success
                      size="sm"
                      variant="flat-success"
                      class="p-md-25 d-inline-block btn-icon"
                      :title="$t('Copy')"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        @click="() => { copy(address.mail) }"
                      />
                    </b-button>
                  </td>
                </tr>

                <tr>
                  <td class="text-right text-muted">
                    {{ $t('Date') }}:
                  </td>

                  <td>
                    {{ formatDate((message.deliveredAt ? message.deliveredAt.date : ( message.createdAt ? message.createdAt.date : '' )), { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
                  </td>

                  <td class="text-right text-muted">
                    <b-button
                      v-b-tooltip.hover.v-success
                      size="sm"
                      variant="flat-success"
                      class="p-md-25 d-inline-block btn-icon"
                      :title="$t('Copy')"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        @click="() => { copy((message.deliveredAt ? message.deliveredAt.date : ( message.createdAt ? message.createdAt.date : '' )), { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }"
                      />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>

      <div
        class="mail-meta-item d-flex flex-wrap align-items-end justify-content-end flex-md-column mt-50 mt-md-0"
        style="row-gap: .5rem; column-gap: .5rem;"
      >
        <div class="d-flex flex-wrap align-items-center">
          <div class="d-flex flex-wrap align-items-end justify-content-end flex-md-column"
               style="row-gap: .5rem; column-gap: .5rem;"
          >
            <b-badge
              v-if="message.mailboxFolder"
              :variant="resolveFilterStatus(message.mailboxFolder).variant"
            >
              <feather-icon :icon="resolveFilterStatus(message.mailboxFolder).icon" />
              {{ findStatus2(message.mailboxFolder) ? $t(findStatus2(message.mailboxFolder).title) : message.mailboxFolder }}
            </b-badge>

            <small class="mail-date-time text-muted text-nowrap">
              {{ formatDate((message.deliveredAt ? message.deliveredAt.date : ( message.createdAt ? message.createdAt.date : '' )), { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
            </small>
          </div>
        </div>

      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <!--        v-html="getHtml(message.contentHtml)"-->
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
      >
        <iframe ref="emailFrame"
                style="width: 100%; height: 500px; border: none;"
        />
      </div>
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer v-if="message.files && message.files.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />

          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            {{ message.files.length }} {{ $tc('Attachments', message.files.length) }}
          </h5>
        </div>

        <div class="d-flex flex-column text-truncate">
          <div
            v-for="(file, index) in message.files"
            :key="`file_${index}`"
            :class="{'mt-75': index}"
            class="cursor-pointer"
            @click="downloadFile(file.token, `${file.name}.${file.extension}`)"
          >
            <feather-icon
              :icon="fileIcon(file.extension)"
              class="mr-50 text-primary"
              size="16"
            />

            <span class="text-muted font-weight-bolder align-text-top">{{ `${file.name}.${file.extension}` }}</span>
          </div>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import fileIcon from '@/@data/fileIcons'
import {
  BCardBody, BCardFooter, BCardHeader, BDropdown, VBTooltip,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import { DOWNLOAD_FILE } from '@/@constants/mutations'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import emailFilters from '@/@types/emailFiltersGlobal'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BDropdown, BCardHeader, BCardBody, BCardFooter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    selectedEmail: {
      required: false,
    },
    showDelete: {
      default: false,
    },
  },
  setup() {
    const resolveFilterBadgeText = filter => {
      if (filter === 'INBOX') return 'text-primary'
      if (filter === 'INBOX.Sent') return 'text-success'
      if (filter === 'INBOX.Spam') return 'text-warning'
      if (filter === 'INBOX.Trash') return 'text-danger'
      if (filter === 'INBOX.Drafts') return 'text-info'
      return 'text-dark'
    }

    const resolveFilterBadgeVariant = filter => {
      if (filter === 'INBOX') return 'light-primary'
      if (filter === 'INBOX.Sent') return 'light-success'
      if (filter === 'INBOX.Spam') return 'light-warning'
      if (filter === 'INBOX.Trash') return 'light-danger'
      if (filter === 'INBOX.Drafts') return 'light-info'
      return 'dark'
    }

    const resolveFilterBadgeIcon = filter => {
      if (filter === 'INBOX') return 'EyeIcon'
      if (filter === 'INBOX.Sent') return 'SendIcon'
      if (filter === 'INBOX.Spam') return 'AlertCircleIcon'
      if (filter === 'INBOX.Trash') return 'TrashIcon'
      if (filter === 'INBOX.Drafts') return 'EditIcon'
      return 'MinusIcon'
    }

    const resolveFilterStatus = filter => {
      const text = resolveFilterBadgeText(filter)
      const variant = resolveFilterBadgeVariant(filter)
      const icon = resolveFilterBadgeIcon(filter)

      return { text, variant, icon }
    }

    const resolveLang = title => {
      if (title) {
        // eslint-disable-next-line no-underscore-dangle
        const _title = title.toLowerCase()
        return _title.charAt(0).toUpperCase() + _title.slice(1)
      }
      return ''
    }

    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      fileIcon,
      formatDate,
      resolveFilterBadgeIcon,
      resolveFilterStatus,
      resolveLang,
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    emailFilters,
  }),
  computed: {
    concatToAddresses() {
      const addressed = this.message.toAddresses.map(address => address.mail)
      return addressed.join(', ')
    },
    concatToCcAddresses() {
      const addressed = this.message.ccAddresses.map(address => address.mail)
      return addressed.join(', ')
    },
  },
  watch: {
    message: {
      deep: true,
      handler(nw, ow) {
        if (nw?.id && ow) {
          this.loadEmailContent()
        }
      },
    },
  },
  mounted() {
    this.loadEmailContent()
  },
  methods: {
    findStatus2(status) {
      if (status === 'INBOX.Sent') {
        return this.emailFilters[2]
      }
      if (status === 'INBOX') {
        return this.emailFilters[1]
      }
      if (status === 'INBOX.Drafts') {
        return this.emailFilters[4]
      }

      if (this.selectedEmail?.userMailboxFolders?.length && this.selectedEmail?.userMailboxFolders.find(e => e.folder === status)) {
        return {
          ...this.selectedEmail?.userMailboxFolders.find(e => e.folder === status),
          variant: 'primary',
          icon: 'CircleIcon',
          title: this.selectedEmail?.userMailboxFolders.find(e => e.folder === status).name,
        }
      }

      return this.emailFilters.find(e => ((this.selectedEmail.sendFolder === status) || (this.selectedEmail.draftFolder === status)) && e?.status?.toLowerCase()?.includes(status?.toLowerCase()))
    },
    loadEmailContent(content = null) {
      const html = (content || (this.message.contentHtml ? this.getHtml(this.message.contentHtml, [...(this.message.files || []), ...(this.message.imageFiles || [])]) : ''))
        .replaceAll('display: none', 'display: block')
        .replaceAll('<img ', '<img style="max-width: 100%;" ')
        .replaceAll('</strong>', ' </strong>')
        .replaceAll('</p>', ' </p>')
        .replaceAll('</span>', ' </span>')
        .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>')

      const iframe = this.$refs.emailFrame
      const doc = iframe.contentDocument || iframe.contentWindow.document

      const parser = new DOMParser()
      const parsedDoc = parser.parseFromString(html, 'text/html')
      // Test
      // Function to wrap quoted content
      const wrapQuotedContent = document => {
        // Identify common patterns for quoted content
        const gmailQuotes = document.querySelectorAll('.gmail_quote, blockquote, blockquote[type="cite"], .yahoo_quoted')
        gmailQuotes.forEach(quote => {
          // Create a collapsible button
          const collapsibleButton = document.createElement('button')
          collapsibleButton.className = 'collapsible'
          collapsibleButton.textContent = this.$i18n.t('ShowMore')

          // Create a collapsible content container
          const collapsibleContent = document.createElement('div')
          collapsibleContent.className = 'collaps-content'
          collapsibleContent.style.display = 'none'
          collapsibleContent.appendChild(quote.cloneNode(true))

          // Insert the button and collapsible content before the quoted content
          quote.parentNode.insertBefore(collapsibleButton, quote)
          quote.parentNode.insertBefore(collapsibleContent, quote)

          // Remove the original quote
          quote.remove()
        })
      }

      // Wrap quoted content
      wrapQuotedContent(parsedDoc)

      // CSS for collapsible elements
      const styles = `
    <style>
      .collapsible {
        cursor: pointer;
        padding: 10px;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: background-color 0.3s ease;
        background-color: #f1f1f1;
      }

      .collapsible:hover {
        background-color: #ddd;
      }

      .collapsible:after {
        content: '▼';
        float: right;
        margin-left: 5px;
      }

      .collapsible.active:after {
        content: '▲';
      }

      .collaps-content {
        padding: 0 18px;
        display: none;
        overflow: hidden;
        background-color: #f9f9f9;
        margin-top: 5px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      p {
        word-break: break-all !important;
        white-space: break-spaces !important;
      }
    </style>
  `

      // JavaScript for collapsible functionality
      const script = `document.addEventListener('DOMContentLoaded', function() {
          const coll = document.querySelectorAll('.collapsible')
          coll.forEach(button => {
              if (!button.classList.contains('initialized')) {
                button.classList.add('initialized');
                button.addEventListener('click', function() {
                    this.classList.toggle('active');
                    const content = this.nextElementSibling;

                    if (content.style.display === 'block') {
                        content.style.display = 'none';
                        this.textContent = '${this.$i18n.t('ShowMore')}';
                    } else {
                        content.style.display = 'block';
                        this.textContent = '${this.$i18n.t('ShowLess')}';
                    }
                }, false);
            }
        })
    }, false)`
      // Test

      doc.open()
      // eslint-disable-next-line
      doc.write(styles + parsedDoc.body.innerHTML + ('<' + 'script' + '>' + script + '</' + 'script' + '>'))
      doc.close()
    },
    copy(txt) {
      this.showToast('success', this.$i18n.t('CopiedToClipboard'))

      navigator.clipboard.writeText(txt)
    },

    async fetchImage(file) {
      try {
        const response = await axiosIns.get(`storage/1/download/${file.token}`)
        return `data:image/${file.extension};base64,${response.data.data.file}`
      } catch (error) {
        console.error(`Error fetching image ${file.url}: `, error)
        return `cid:${file.name}` // Fallback to cid if fetching fails
      }
    },

    // Queue implementation with delay
    async downloadImagesWithDelay(htmlString, files, delay) {
      const x = htmlString
      const elem = document.createElement('div')
      elem.innerHTML = x
      let index = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (['png', 'webp', 'jpg', 'jpeg'].includes(file.extension)) {
          // eslint-disable-next-line no-await-in-loop
          const imageUrl = await this.fetchImage(file)
          let dynamicStart = `cid:${file.name}.${file.extension}`
          let selector = `img[src^="${dynamicStart}"]`
          let element = elem.querySelector(selector)

          if (element) {
            element.src = imageUrl
          } else {
            dynamicStart = `cid:${file.name}`
            selector = `img[src^="${dynamicStart}"]`
            element = elem.querySelector(selector)
            if (element) {
              element.src = imageUrl
            } else {
              dynamicStart = `//:${index}`
              selector = `img[src^="${dynamicStart}"]`
              element = elem.querySelector(selector)
              if (element) {
                element.src = imageUrl
              } else {
                dynamicStart = 'cid:'
                selector = `img[src^="${dynamicStart}"]`
                element = elem.querySelector(selector)
                if (element) {
                  element.src = imageUrl
                }
              }
            }
          }
          // eslint-disable-next-line no-await-in-loop
          await new Promise(resolve => setTimeout(resolve, delay))
        }

        // eslint-disable-next-line no-plusplus
        index++
      }

      return elem.innerHTML
    },

    getHtml(content = '', files = []) {
      let x = content.replaceAll('<a', '<a target="_blank"')
      if (!x.includes('style') && !x.includes('src=')) {
        x = x.replaceAll('\n', '<br>')
      }

      this.downloadImagesWithDelay(x, files.filter(a => ['png', 'webp', 'jpg', 'jpeg'].includes(a.extension)), 200)
        .then(e => {
          this.loadEmailContent(e)
        })
      return x
    },
    downloadFile(fileToken, filename_) {
      this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken, filename: filename_ })
        .then(res => {
          const { data, filename } = res
          const a = document.createElement('a')
          a.href = `data:${data.type};base64,${data.file}`
          a.download = filename
          a.click()
        })
    },
  },
}
</script>

<style lang="scss">
</style>
