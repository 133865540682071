<template>
  <b-card no-body>
    <div
      v-if="!loading"
      class="email-application"
    >
      <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
      <div v-if="selectedEmail && !invalidCredentials"
           class="content-area-wrapper"
      >
        <left-sidebar
          :key="getKeys().length"
          :class="{'show': mqShallShowLeftSidebar}"
          :active-status="filters.status"
          :per-page="pagination.perPage"
          :statistics="stats"
          :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
          :email="selectedEmail"
          :all-emails="allEmails"
          :all-keys="getKeys()"
          :total-emails="totalEmails"
          @close-left-sidebar="mqShallShowLeftSidebar = false"
          @set-message-status="setMessagesStatus"
          @set-per-page="setMessagesPerPage"
          @set-email="setEmail"
          @reload="() => {
            getStatistics();
            loadEmails();
          }"
        />
        <!--          <b-overlay no-wrap-->
        <!--                     :show="loadingOverlay"-->
        <!--                     spinner-variant="primary"-->
        <!--          />-->

        <div class="content-right">
          <div class="content-wrapper">
            <div class="content-body">
              <div style="height: inherit">
                <div
                  class="body-content-overlay"
                  :class="{'show': mqShallShowLeftSidebar}"
                  @click="mqShallShowLeftSidebar = false"
                />

                <!-- Email List -->
                <div class="email-app-list">

                  <!-- App Searchbar Header -->
                  <div class="app-fixed-search d-flex align-items-center">

                    <!-- Toggler -->
                    <div class="sidebar-toggle d-block d-lg-none ml-1">
                      <feather-icon
                        icon="MenuIcon"
                        size="21"
                        class="cursor-pointer"
                        @click="mqShallShowLeftSidebar = true"
                      />
                    </div>

                    <!-- Searchbar -->
                    <div class="d-flex align-content-center justify-content-between w-100">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="SearchIcon"
                            class="text-muted"
                          />
                        </b-input-group-prepend>

                        <b-form-input
                          v-model="filters.search"
                          :placeholder="$t('Search')"
                          @change="search"
                        />
                      </b-input-group>

                      <div class="d-flex align-items-center">
                        <b-button v-b-tooltip
                                  size="sm"
                                  class="mr-25 d-flex flex-nowrap"
                                  :variant="selectedView === 'GLOBAL' ? 'primary' : 'light-primary'"
                                  :title="$t('EmailMessages')"
                                  @click="selectedView = 'GLOBAL'; loadEmails()"
                        >
                          <sw-icon icon="GridIcon" />
                        </b-button>
                        <b-button v-b-tooltip
                                  size="sm"
                                  class="mr-25 d-flex flex-nowrap"
                                  :variant="selectedView === 'THREAD' ? 'primary' : 'light-primary'"
                                  :title="$t('EmailThreads')"
                                  @click="selectedView = 'THREAD'; loadEmails()"
                        >
                          <sw-icon icon="DatabaseIcon" />
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <!-- App Action Bar -->
                  <div
                    class="app-action d-flex flex-wrap"
                    style="row-gap: .5rem; column-gap: 1rem"
                  >
                    <div class="action-left">
                      <b-form-checkbox v-model="selectAll"
                                       variant="primary"
                                       @change="selectAllEmails"
                      >
                        {{ $t('SelectAll') }}
                      </b-form-checkbox>
                    </div>

                    <div class="align-items-center">
                      <!-- Update Mail Folder Dropdown -->
                      <b-dropdown
                        variant="link"
                        no-caret
                        toggle-class="p-0"
                        dropleft
                      >
                        <template #button-content>
                          <feather-icon
                            v-b-tooltip.hover.v-primary
                            icon="FolderIcon"
                            size="17"
                            class="align-middle text-body"
                            :title="$t('Move')"
                          />
                        </template>
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150 }"
                                               style="height: 300px"
                        >
                          <b-dropdown-item
                            v-for="(filter, index) in getKeys()"
                            :key="`${filter}_${index}`"
                            @click="moveSelectedItemsTo(stats[filter].orginalKey)"
                          >
                            <div class="d-flex align-items-center">
                              <feather-icon
                                :icon="findStatus(filter) ? findStatus(filter).icon : 'CircleIcon' || 'MinusIcon'"
                                :class="`text-${findStatus(filter) ? findStatus(filter).variant : 'primary'}`"
                                class="mr-50"
                                size="18"
                              />

                              <span class="align-text-bottom line-height-1">{{ findStatus(filter) ? $t(findStatus(filter).title) : filter }}</span>
                            </div></b-dropdown-item>
                        </vue-perfect-scrollbar>
                        <!--                        <b-dropdown-item @click="moveSelectedItemsTo('INBOX.Spam')">-->
                        <!--                          <feather-icon icon="InfoIcon" />-->
                        <!--                          <span class="align-middle ml-50">{{ $t('Spam') }}</span>-->
                        <!--                        </b-dropdown-item>-->

                        <!--                        <b-dropdown-item @click="moveSelectedItemsTo('INBOX.Trash')">-->
                        <!--                          <feather-icon icon="TrashIcon" />-->
                        <!--                          <span class="align-middle ml-50">{{ $t('Trash') }}</span>-->
                        <!--                        </b-dropdown-item>-->
                      </b-dropdown>

                      <feather-icon
                        v-b-tooltip.hover.v-primary
                        :title="$t('AssignToThread')"
                        icon="LinkIcon"
                        size="17"
                        class="cursor-pointer ml-1"
                        @click.stop="openModal"
                      />

                      <feather-icon
                        v-show="$route.params.folder !== 'trash'"
                        v-b-tooltip.hover.v-danger
                        icon="TrashIcon"
                        size="17"
                        :title="$t('Delete')"
                        class="cursor-pointer ml-1"
                        @click="deleteSelectedItems(null)"
                      />

                      <feather-icon
                        v-show="$route.params.folder !== 'trash'"
                        v-b-tooltip.hover.v-primary
                        :icon="!isSmallView ? 'ListIcon' : 'ServerIcon'"
                        size="17"
                        class="cursor-pointer ml-1"
                        :title="$t('SwitchView')"
                        @click="isSmallView = !isSmallView"
                      />
                    </div>
                  </div>

                  <b-overlay no-wrap
                             :show="loadingOverlay"
                             spinner-variant="primary"
                  />

                  <!-- Emails List -->
                  <vue-perfect-scrollbar
                    ref="scrollArea"
                    :key="`a_${loadingOverlay}`"
                    :settings="{ maxScrollbarLength: 150, suppressScrollY: loadingOverlay }"
                    class="email-user-list scroll-area"
                  >
                    <div v-if="!isSmallView">
                      <ul
                        class="email-media-list"
                      >
                        <b-media
                          v-for="mail in emails"
                          :key="mail.id"
                          tag="li"
                          no-body
                          :class="{ 'mail-read': !mail.userRead }"
                          @click="openEmailDetails(mail)"
                        >
                          <b-media-aside class="media-left mr-50">
                            <b-avatar
                              class="avatar"
                              size="40"
                              variant="light-primary"
                              style="background-color: var(--primary)"
                              text="S"
                              :src="mail.createdBy ? mail.createdBy.avatar : ''"
                            />

                            <div class="user-action">
                              <b-form-checkbox v-model="selectedEmails"
                                               variant="primary"
                                               :value="mail.id"
                                               @click.native.stop
                              />

                            <!--                            <div class="email-favorite">-->
                            <!--                              <feather-icon-->
                            <!--                                icon="StarIcon"-->
                            <!--                                size="17"-->
                            <!--                              />-->
                            <!--                            </div>-->
                            </div>
                          </b-media-aside>

                          <b-media-body>
                            <div class="mail-details">
                              <div class="mail-items">
                                <div
                                  class="mb-25 d-flex flex-wrap align-items-center"
                                  style="column-gap: .34rem"
                                >
                                  <p
                                    v-b-tooltip.hover.v-primary
                                    class="h6 mb-0"
                                    :title="$t('Sender')"
                                  >
                                    {{ mail.createdBy ? `${mail.createdBy.firstName} ${mail.createdBy.lastName}` : mail.fromMail }}
                                  </p>

                                  <div
                                    v-for="(address, i) in mail.toAddresses"
                                    :key="i"
                                    class="d-flex align-items-center justify-content-center"
                                    style="column-gap: .5rem; row-gap: .5rem;"
                                  >
                                    <feather-icon icon="ArrowRightIcon" />

                                    <p
                                      v-b-tooltip.hover.v-primary
                                      class="h6 mb-0"
                                      :title="$t('Recipient')"
                                    >
                                      {{ address.mail }}
                                    </p>
                                  </div>

                                  <b-badge
                                    v-if="!mail.conversation || !mail.conversation.contactThread"
                                    variant="primary"
                                    @click.stop="() => {
                                      selectedEmails = [mail.id];
                                      openModal()
                                    }"
                                  >
                                    <div
                                      class="d-flex align-items-start"
                                      style="column-gap: .34rem"
                                      @click.stop="() => {
                                        selectedEmails = [mail.id];
                                        openModal()
                                      }"
                                    >
                                      <feather-icon icon="LinkIcon" />

                                      <span>
                                        {{ $t('AssignToProcess') }}
                                      </span>
                                    </div>
                                  </b-badge>

                                  <b-badge
                                    v-else
                                    v-b-tooltip.hover.v-primary
                                    variant="light-primary"
                                    :title="`${$t('AssignedTo')} ${mail.conversation.contactThread.name}`"
                                  >
                                    <div
                                      class="d-flex align-items-start"
                                      style="column-gap: .34rem"
                                    >
                                      <feather-icon icon="LinkIcon" />

                                      <span>
                                        {{ `${ mail.conversation.contactThread.contact.firstName } ${ mail.conversation.contactThread.contact.lastName }` }}
                                        <feather-icon icon="ArrowRightIcon" />
                                        {{ mail.conversation.contactThread.name }}
                                      </span>
                                    </div>
                                  </b-badge>
                                </div>

                                <strong class="text-truncate">
                                  {{ mail.subject }}
                                </strong>
                              </div>

                              <div
                                class="mail-meta-item d-flex align-items-start"
                                style="column-gap: .5rem"
                              >
                                <!-- Single Email Files Icon -->
                                <b-badge v-if="mail.files.length"
                                         v-b-tooltip
                                         variant="primary"
                                         :title="$t('DownloadFiles')"
                                         @click.stop="downloadAllFiles(mail.files)"
                                >
                                  <feather-icon
                                    icon="PaperclipIcon"
                                  />
                                </b-badge>

                                <feather-icon
                                  v-if="mail.status"
                                  v-b-tooltip.hover
                                  :class="resolveFilterStatus(mail.mailboxFolder).text"
                                  :icon="resolveFilterStatus(mail.mailboxFolder).icon"
                                  :title="findStatus(mail.mailboxFolder) ? $tc(findStatus(mail.mailboxFolder).title, 1) : mail.mailboxFolder"
                                />
                                <!-- Single Email Date -->
                                <span class="mail-date">
                                  {{ formatDate((mail.deliveredAt ? mail.deliveredAt.date : ( mail.createdAt ? mail.createdAt.date : '' )), { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
                                </span>
                              </div>
                            </div>

                            <div class="text-truncate">
                              {{ mail.contentHtml | htmlToText }}
                            </div>
                          </b-media-body>
                        </b-media>
                      </ul>

                      <b-pagination
                        v-if="emails.length && !showEmailDetails"
                        :value="pagination.page"
                        :total-rows="pagination.total"
                        :per-page="pagination.perPage"
                        class="mt-1"
                        aria-controls="table"
                        align="center"
                        @change="cp => {
                          pagination.page = cp
                          loadEmails()
                        }"
                      />
                    </div>

                    <div v-else>
                      <ul class="email-media-list">
                        <b-media
                          v-for="mail in emails"
                          :key="mail.id"
                          tag="li"
                          no-body
                          :class="{ 'mail-read': !mail.userRead }"
                          class="py-50"
                          @click="openEmailDetails(mail)"
                        >
                          <b-media-aside
                            class="media-left flex-md-row flex-wrap"
                            style="row-gap: .14rem; column-gap: .5rem"
                          >
                            <div
                              class="media-left flex-row"
                              style="column-gap: .5rem"
                            >
                              <div class="user-action">
                                <b-form-checkbox
                                  v-model="selectedEmails"
                                  :value="mail.id"
                                  @click.native.stop
                                />

                                <!--                                <div class="email-favorite">-->
                                <!--                                  <feather-icon-->
                                <!--                                    icon="StarIcon"-->
                                <!--                                    size="17"-->
                                <!--                                  />-->
                                <!--                                </div>-->
                              </div>

                              <b-avatar
                                size="20"
                                variant="light-primary"
                                style="background-color: var(--primary)"
                                text="S"
                                :src="mail.createdBy ? mail.createdBy.avatar : ''"
                              />

                              <strong class="text-truncate d-md-none">{{ mail.subject | truncate(14) }}</strong>
                            </div>

                            <div
                              class="mail-items d-flex flex-wrap ml-md-50"
                              style="column-gap: 1rem"
                            >
                              <strong class="text-truncate d-none d-md-inline-block">{{ mail.subject | truncate(14) }}</strong>

                              <span class="text-truncate">{{ mail.contentHtml | htmlToText | truncate(34) }}</span>
                            </div>

                            <div>
                              <b-badge
                                v-if="!mail.conversation || !mail.conversation.contactThread"
                                v-b-tooltip.hover.v-primary
                                variant="primary"
                                :title="`${$t('AssignToThread')} X`"
                                @click.stop="openModal"
                              >
                                <div
                                  class="d-flex align-items-start"
                                  style="column-gap: .34rem"
                                  @click.stop="openModal"
                                >
                                  <feather-icon icon="LinkIcon" />

                                  <span>
                                    {{ $t('AssignToProcess') }}
                                  </span>
                                </div>
                              </b-badge>

                              <b-badge
                                v-else
                                v-b-tooltip.hover.v-primary
                                variant="light-primary"
                                :title="`${$t('AssignedTo')} ${mail.conversation.contactThread.name}`"
                              >
                                <div
                                  class="d-flex align-items-start"
                                  style="column-gap: .34rem"
                                >
                                  <feather-icon icon="LinkIcon" />

                                  <span>
                                    {{ `${ mail.conversation.contactThread.contact.firstName } ${ mail.conversation.contactThread.contact.lastName }` }}
                                    <feather-icon icon="ArrowRightIcon" />
                                    {{ mail.conversation.contactThread.name }}
                                  </span>
                                </div>
                              </b-badge>
                            </div>
                          </b-media-aside>

                          <b-media-body>
                            <div class="mail-details m-0">
                              <div class="mail-items" />

                              <div
                                class="mail-meta-item d-flex align-items-start"
                                style="column-gap: .5rem"
                              >
                                <!-- Single Email Files Icon -->
                                <b-badge v-if="mail.files.length"
                                         v-b-tooltip
                                         variant="primary"
                                         :title="$t('DownloadFiles')"
                                         @click.stop="downloadAllFiles(mail.files)"
                                >
                                  <feather-icon
                                    icon="PaperclipIcon"
                                  />
                                </b-badge>

                                <feather-icon
                                  v-if="mail.mailboxFolder"
                                  v-b-tooltip.hover
                                  :class="resolveFilterStatus(mail.mailboxFolder).text"
                                  :icon="resolveFilterStatus(mail.mailboxFolder).icon"
                                  :title="$tc(`gEmail.status.${mail.mailboxFolder.replaceAll('.', '_')}`, 1)"
                                />

                                <!-- Single Email Date -->
                                <span class="mail-date">
                                  {{ formatDate((mail.deliveredAt ? mail.deliveredAt.date : ( mail.createdAt ? mail.createdAt.date : '' )), { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
                                </span>
                              </div>
                            </div>
                          </b-media-body>
                        </b-media>
                      </ul>

                      <b-pagination
                        v-if="emails.length"
                        :value="pagination.page"
                        :total-rows="pagination.total"
                        :per-page="pagination.perPage"
                        class="mt-1"
                        aria-controls="table"
                        align="center"
                        @change="cp => {
                          pagination.page = cp
                          loadEmails()
                        }"
                      />
                    </div>

                    <div
                      class="no-results"
                      :class="{'show': !emails.length && !loadingOverlay}"
                    >
                      <h5>{{ $t('NoData') }}</h5>
                    </div>
                  </vue-perfect-scrollbar>
                </div>

                <!-- Email View/Detail -->
                <close-up
                  :class="{'show': showEmailDetails}"
                  :email-view-data="emailViewData"
                  :selected-email="selectedEmail"
                  :all-keys="getKeys()"
                  @close-email-view="showEmailDetails = false; selectedEmails = []"
                  @assign-to-thread="openModal"
                  @open-action-form="(e) => {
                    actionData = e
                    shallShowEmailComposeModal = true
                  }"
                  @move-to-folder="moveSelectedItemsTo"
                  @remove-item="deleteSelectedItems"
                />

                <!-- Compose Email Modal -->
                <compose
                  v-if="shallShowEmailComposeModal"
                  v-model="shallShowEmailComposeModal"
                  :action-data="actionData"
                  @save="() => {
                    loadEmails()
                    getStatistics()
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="invalidCredentials"
           class="d-flex flex-column justify-content-center align-items-center text-center my-3"
      >
        <h5 class="my-1">
          {{ $t('InvalidMailCredentials') }}
        </h5>
        <v-select
          v-model="selectedEmail"
          label="name"
          :options="allEmails"
          :placeholder="$t('gEmail.email')"
          style="min-width: 300px"
          @input="selectInbox"
        >
          <template #no-options>
            <span>
              {{ $t('NoData') }}
            </span>
          </template>
        </v-select>
        <div class="divider text-primary">
          <div class="divider-text">
            {{ $t('Or') }}
          </div>
        </div>
        <b-button :disabled="!selectedEmail || loadingOverlay"
                  variant="primary"
                  @click="getStatistics"
        >
          <sw-icon icon="RefreshIcon" />
          {{ $t('Refresh') }}
        </b-button>
      </div>

      <!-- First Load -->
      <div v-else
           class="d-flex justify-content-center align-items-center text-center my-3"
      >
        <div>
          <b-img src="@/assets/images/pages/coming-soon.svg"
                 style="width: 300px"
          />
          <h5 class="my-1">
            {{ $t('SelectEmailBox') }}
          </h5>
          <v-select
            v-model="selectedEmail"
            label="name"
            :options="allEmails"
            :placeholder="$t('gEmail.email')"
            @input="selectInbox"
          >
            <template #no-options>
              <span>
                {{ $t('NoData') }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>

    <b-modal v-model="showAssignThreadModal"
             :title="$t('AssignToThread')"
             hide-footer
             no-close-on-backdrop
    >
      <b-form-group>
        <sw-select :name="$t('SelectContacts')">
          <v-select
            v-model="selectedThread"
            :options="threadList"
            label="name"
            :filterable="false"
            @search="loadThreads"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
            <template #option="{ name, contact }">
              <div class="d-flex align-items-center">
                <avatar
                  :user="contact"
                  class="mr-25"
                />  - {{ name }}
              </div>
            </template>

            <template #selected-option="{ name, contact }">
              <div class="d-flex">
                <avatar
                  :user="contact"
                  class="mr-25"
                /> – {{ name }}
              </div>
            </template>
          </v-select>
        </sw-select>
      </b-form-group>

      <!--      Button: Save      -->
      <b-button
        size="sm"
        variant="primary"
        :disabled="!selectedThread"
        @click="assignToThread"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>

  </b-card>
</template>

<script>
import {
  BMedia, BMediaAside, BMediaBody, VBTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { DOWNLOAD_FILE, GET_GLOBAL_EMAILS, GET_USER_MAILBOXES } from '@/@constants/mutations'
import store from '@/store'
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import { THREADS_SEARCH } from '@/@constants/fields'
import emailFilters from '@/@types/emailFiltersGlobal'
import LeftSidebar from './LeftSidebar.vue'
import CloseUp from './CloseUp.vue'
import Compose from './Compose.vue'

export default {
  name: 'Emails',
  components: {
    vSelect,
    LeftSidebar,
    CloseUp,
    VuePerfectScrollbar,
    BMedia,
    BMediaAside,
    BMediaBody,
    Compose,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const resolveFilterBadgeText = filter => {
      if (filter === 'INBOX') return 'text-primary'
      if (filter === 'INBOX.Sent') return 'text-success'
      if (filter === 'INBOX.Spam') return 'text-warning'
      if (filter === 'INBOX.Trash') return 'text-danger'
      if (filter === 'INBOX.Drafts') return 'text-info'
      return 'text-dark'
    }

    const resolveFilterBadgeVariant = filter => {
      if (filter === 'INBOX') return 'light-primary'
      if (filter === 'INBOX.Sent') return 'light-success'
      if (filter === 'INBOX.Spam') return 'light-warning'
      if (filter === 'INBOX.Trash') return 'light-danger'
      if (filter === 'INBOX.Drafts') return 'light-info'
      return 'dark'
    }

    const resolveFilterBadgeIcon = filter => {
      if (filter === 'INBOX') return 'EyeIcon'
      if (filter === 'INBOX.Sent') return 'SendIcon'
      if (filter === 'INBOX.Spam') return 'AlertCircleIcon'
      if (filter === 'INBOX.Trash') return 'TrashIcon'
      if (filter === 'INBOX.Drafts') return 'EditIcon'
      return 'MinusIcon'
    }

    const resolveFilterStatus = filter => {
      const text = resolveFilterBadgeText(filter)
      const variant = resolveFilterBadgeVariant(filter)
      const icon = resolveFilterBadgeIcon(filter)

      return { text, variant, icon }
    }

    // Compose
    const shallShowEmailComposeModal = ref(false)

    return {
      resolveFilterBadgeText,
      resolveFilterBadgeVariant,
      resolveFilterBadgeIcon,
      resolveFilterStatus,
      shallShowEmailComposeModal,
    }
  },
  data: () => ({
    selectedView: 'GLOBAL', // THREAD | GLOBAL
    selectAll: false,
    selectedEmails: [],
    isSmallView: false,

    searchQuery: '',
    filters: {
      status: 'ALL',
      search: '',
    },
    sorter: {
      sortBy: 'deliveredAt',
      sortDesc: true,
    },
    pagination: {
      total: 0,
      perPage: 25,
      page: 1,
    },

    showAssignThreadModal: false,
    selectedThread: null,
    threadList: [],
    actionData: null,

    mqShallShowLeftSidebar: false,
    showEmailDetails: false,
    emailViewData: {},
    emails: [],
    loading: false,
    invalidCredentials: false,
    loadingOverlay: false,
    stats: {
      INBOX: {
        all: 0,
        unread: 0,
        orginalKey: null,
      },
      'INBOX.Sent': {
        all: 0,
        unread: 0,
        orginalKey: null,
      },
      'INBOX.Drafts': {
        all: 0,
        unread: 0,
        orginalKey: null,
      },
    },
    selectedEmail: '',
    allEmails: [],
    emailFilters,
    totalEmails: { unread: 0, all: 0 },
  }),
  watch: {
    shallShowEmailComposeModal(nw) {
      if (!nw) this.actionData = null
    },
    selectedEmails: {
      handler() {
        this.selectAll = this.selectedEmails.length === this.emails.length
      },
      deep: true,
    },
  },
  async mounted() {
    const { items } = await this.$store.dispatch(`mailbox/${GET_USER_MAILBOXES}`)

    this.allEmails = items.filter(a => a.active)

    if (this.allEmails.length) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedEmail = this.allEmails[0]
      await this.selectInbox(this.allEmails[0])
    }
  },
  methods: {
    findStatus(status) {
      if (status === 'INBOX.Sent') {
        return this.emailFilters[2]
      }

      if (status === 'INBOX') {
        return this.emailFilters[1]
      }

      if (status === 'INBOX.Drafts') {
        return this.emailFilters[4]
      }

      if (this.selectedEmail?.userMailboxFolders?.length && this.selectedEmail?.userMailboxFolders.find(e => e.folder === status)) {
        return {
          ...this.selectedEmail?.userMailboxFolders.find(e => e.folder === status),
          variant: 'primary',
          icon: 'CircleIcon',
          title: this.selectedEmail?.userMailboxFolders.find(e => e.folder === status).name,
        }
      }

      return this.emailFilters.find(e => ((this.selectedEmail.sendFolder === status) || (this.selectedEmail.draftFolder === status)) && e?.status?.toLowerCase()?.includes(status?.toLowerCase()))
    },
    getKeys() {
      return [...Object.keys(this.stats).slice(0, 2), ...Object.keys(this.stats).slice(2).sort((a, b) => {
        const aInArray = this.findStatus(a)
        const bInArray = this.findStatus(b)

        if (aInArray && !bInArray) return -1
        if (!aInArray && bInArray) return 1
        return a.localeCompare(b)
      })]
    },
    async loadThreads(lookUp, isLoading) {
      this.threadList = await this.selectSearch(lookUp, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName,contact-mailAddress-mail,contact-phoneNumber-phone', '1/contacts/threads')
    },
    openModal() {
      this.showAssignThreadModal = true
      this.selectedThread = null
    },
    selectAllEmails() {
      this.selectedEmails = this.selectAll ? this.emails.map(mail => mail.id) : []
    },
    formatDate,
    async selectInbox(userMailbox) {
      this.pagination.page = 1
      this.loadingOverlay = true
      this.$set(this, 'stats', {
        INBOX: {
          all: 0,
          unread: 0,
          orginalKey: null,
        },
        'INBOX.Sent': {
          all: 0,
          unread: 0,
          orginalKey: null,
        },
        'INBOX.Drafts': {
          all: 0,
          unread: 0,
          orginalKey: null,
        },
      })
      if (userMailbox) {
        try {
          this.totalEmails = { unread: 0, all: 0 }
          const resp = await axiosIns.get(`1/userMailboxes/${this.getObjectId(userMailbox)}/mails/getStatistics`)
          const items = resp.data.data
          console.log(items)

          const mailboxFolders = userMailbox?.userMailboxFolders || []

          Object.keys(items).forEach(key => {
            if (key === userMailbox.sendFolder) {
              this.stats['INBOX.Sent'] = { ...items[key], orginalKey: this.selectedEmail.sendFolder, hide: false }
            } else if (key === this.selectedEmail.draftFolder) {
              this.stats['INBOX.Drafts'] = { ...items[key], orginalKey: this.selectedEmail.draftFolder, hide: false }
            } else if (key === this.selectedEmail.sendFolder) {
              this.stats['INBOX.Sent'] = { ...items[key], hide: false }
            } else {
              if (!this.stats[key]) {
                this.stats[key] = {
                  all: 0, unread: 0, orginalKey: key, hide: true,
                }
              }

              this.$set(this.stats, key, {
                ...items[key], orginalKey: key, hide: !mailboxFolders.find(e => e.folder === key), title: mailboxFolders.find(e => e.folder === key)?.name,
              })
            }

            this.totalEmails.all += Number(items[key]?.all || 0)
            this.totalEmails.unread += Number(items[key]?.unread || 0)
          })
          await this.loadEmails()
          this.invalidCredentials = false
        } catch (err) {
          if (err?.response?.data?.message === 'INVALID_CREDENTIALS') {
            // InvalidMailCredentials
            this.invalidCredentials = true
          }
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }
      this.loading = false
    },
    getHtml(content = '') {
      const d = document.createElement('div')
      d.innerHTML = `<div>${content}</div>`
      if (d.innerHTML && d.querySelector('#saleswizard-content-html')) return d.querySelector('#saleswizard-content-html').innerHTML

      const formatedContent = content.replaceAll('\n', '<br />')
      return formatedContent
    },
    async loadEmails() {
      this.loadingOverlay = true

      this.$nextTick(() => {
        this.$refs.scrollArea.$el.scrollTop = 0
      })

      let responseData = null
      if (this.selectedView === 'GLOBAL') {
        const { data } = await store.dispatch(`globalEmails/${GET_GLOBAL_EMAILS}`, {
          pagination: this.pagination,
          searchQuery: this.searchQuery,
          sorter: this.sorter,
          filters: { ...this.filters, selectedEmail: this.getObjectId(this.selectedEmail) },
        })

        responseData = data
      } else {
        const { data } = await store.dispatch('globalEmails/GET_GLOBAL_THREAD_EMAILS', {
          pagination: this.pagination,
          searchQuery: this.searchQuery,
          sorter: this.sorter,
          filters: { ...this.filters, selectedEmail: this.getObjectId(this.selectedEmail) },
        })

        responseData = data

        responseData.items = (data.items || []).map(thread => {
          const mailData = {
            mailThread: {
              id: thread.id,
              mailLastMsgId: thread.mailLastMsgId,
              mailMsgIds: thread.mailMsgIds,
            },
            ...(thread.mailLast || {}),
          }

          if (this.filters.status.toUpperCase() !== 'ALL' && thread.mails?.length) {
            const folderEmail = thread.mails.find(e => e.mailboxFolder === this.filters.status)
            if (folderEmail) {
              return {
                mailThread: {
                  id: thread.id,
                  mailLastMsgId: thread.mailLastMsgId,
                  mailMsgIds: thread.mailMsgIds,
                },
                email: { ...(thread.mailLast || {}) },
                ...(folderEmail || {}),
              }
            }
          }

          return mailData
        })
      }

      this.payments = responseData.items
      this.pagination.total = responseData.totalItemCount

      this.emails = responseData.items
      this.loadingOverlay = false
    },
    openEmailDetails(email) {
      if (email.mailboxFolder.toLowerCase().includes('draf')) {
        this.actionData = {
          type: 'DRAFT', id: email.id, contactThread: email.conversation ? email.conversation.contactThread : null, content: email.contentHtml, subject: email.subject, imap: email.userMailbox, toAddresses: (email.toAddresses || []).map(e => ({ email: e.mail })), imageFiles: email.imageFiles,
        }
        this.shallShowEmailComposeModal = true
        return
      }

      if (!email.userRead) {
        Object.keys(this.stats).forEach(key => {
          if (this.stats[key].orginalKey === email.mailboxFolder) {
            // eslint-disable-next-line no-plusplus
            this.stats[key].unread--
            // eslint-disable-next-line no-plusplus
            this.totalEmails.unread--
          }
        })
        this.markAsReaded(email.id)
      }

      this.selectedEmails = [email.id]
      this.showEmailDetails = true
      this.emailViewData = email
    },
    setMessagesStatus(status) {
      this.pagination.page = 1

      this.filters.status = status
      this.emailViewData = {}
      this.showEmailDetails = false
      this.loadEmails()
    },
    setMessagesPerPage(perPage) {
      this.pagination.perPage = perPage
      this.loadEmails()
    },
    setEmail(emailId) {
      this.selectedEmail = emailId
      this.selectInbox(emailId)
      // this.getStatistics()
    },
    search(search) {
      this.searchQuery = search
      this.getStatistics()
    },
    async deleteSelectedItems(selectedId = null) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('MessageWillBeDeletedFromImapServer'))
      if (!confirmed) return

      if (selectedId) {
        this.selectedEmails = [selectedId]
      }

      try {
        await axiosIns.delete('1/userMailboxes/mails', { data: JSON.stringify(this.selectedEmails) })

        this.selectedEmails.forEach(id => {
          const index = this.emails.findIndex(e => e.id === id)
          if (index > -1) this.$delete(this.emails, index)

          if (this.emailViewData?.id === id) {
            this.$set(this, 'showEmailDetails', false)
            this.$set(this, 'emailViewData', {})
          }
        })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        // await this.loadEmails()
        await this.loadStats()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    // eslint-disable-next-line consistent-return
    async moveSelectedItemsTo(folder) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed || !folder) return false

      try {
        const payload = this.selectedEmails.map(id => ({ id, mailboxFolder: folder }))
        await axiosIns.patch('1/userMailboxes/mails', payload)

        this.selectedEmails.forEach(id => {
          const index = this.emails.findIndex(e => e.id === id)
          if (index > -1) this.$set(this.emails[index], 'mailboxFolder', folder)

          if (this.filters.status !== 'ALL') {
            this.$delete(this.emails, index)
          }

          if (this.emailViewData?.id === id) {
            this.$set(this.emailViewData, 'mailboxFolder', folder)
          }
        })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.clearData()
        // await this.loadEmails()
        await this.loadStats()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    // eslint-disable-next-line consistent-return
    async markAsReaded(id = null) {
      if (!id) {
        const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        if (!confirmed) return false
      }

      try {
        const payload = id
          ? { id, userRead: true }
          : this.selectedEmails.map(selectedId => ({ id: selectedId, userRead: true }))

        await axiosIns.patch('1/userMailboxes/mails', payload)

        if (!id) {
          this.loadEmails()
          this.getStatistics()
        } else {
          const index = this.emails.findIndex(e => e.id === id)
          if (index > -1) this.$set(this.emails[index], 'userRead', true)
        }

        // this.clearData()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    // eslint-disable-next-line consistent-return
    async assignToThread() {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.selectedView === 'THREAD' ? this.$i18n.t('AllAssignedToMailThread') : '')
      if (!confirmed || !this.selectedThread) return false

      try {
        if (this.selectedView === 'THREAD') {
          const mailThreads = this.selectedEmails.map(id => this.emails.find(e => e.id === id)).filter(Boolean)
          const requests = mailThreads.map(e => axiosIns.patch(`1/mailThreads/${e.mailThread.id}`, { id: e.mailThread.id, contactThread: this.getObjectId(this.selectedThread) }))
          if (requests.length) await Promise.all(requests)
        } else {
          const payload = this.selectedEmails.map(id => ({ id, contactThread: this.getObjectId(this.selectedThread) }))
          await axiosIns.patch('1/userMailboxes/mails', payload)
        }

        this.selectedEmails.forEach(id => {
          const index = this.emails.findIndex(e => e.id === id)
          if (index > -1) {
            if (!this.emails[index].conversation) {
              this.$set(this.emails[index], 'conversation', { contactThread: this.selectedThread })
            } else {
              this.$set(this.emails[index].conversation, 'contactThread', this.selectedThread)
            }
          }

          if (this.emailViewData?.id === id) {
            if (!this.emailViewData.conversation) {
              this.$set(this.emailViewData, 'conversation', { contactThread: this.selectedThread })
            } else {
              this.$set(this.emailViewData.conversation, 'contactThread', this.selectedThread)
            }
          }
        })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.clearData()

        // await this.loadEmails()
        // await this.getStatistics()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadStats() {
      const filters = { ...this.filters, selectedEmail: this.getObjectId(this.selectedEmail) }
      const params = {}
      if (this.searchQuery && this.searchQuery.length) {
        // eslint-disable-next-line no-underscore-dangle
        const _search = this.searchQuery.replaceAll(' ', '%')
        params.search = `%${_search}%`
        params.search_fields = [
          'fromMail',
          'createdBy-firstName',
          'createdBy-lastName',
          'toAddresses-mail',
          'subject',
          'contentHtml',
        ].join(',')
      }

      // const response = await axiosIns.get('1/userMailboxes/mails/getStatistics', { params })
      // console.log(response)

      const resp = await axiosIns.get(`1/userMailboxes/${filters.selectedEmail}/mails/getStatistics`, { params })
      const items = resp.data.data

      this.$set(this, 'stats', {
        INBOX: {
          all: 0,
          unread: 0,
          orginalKey: null,
        },
        'INBOX.Sent': {
          all: 0,
          unread: 0,
          orginalKey: null,
        },
        'INBOX.Drafts': {
          all: 0,
          unread: 0,
          orginalKey: null,
        },
      })

      const mailboxFolders = this.selectedEmail?.userMailboxFolders || []

      this.totalEmails = { unread: 0, all: 0 }
      Object.keys(items).forEach(key => {
        if (key === this.selectedEmail.sendFolder) {
          this.stats['INBOX.Sent'] = { ...items[key], orginalKey: this.selectedEmail.sendFolder, hide: (this.selectedEmail.hideFolders || []).includes(key) }
        } else if (key === this.selectedEmail.draftFolder) {
          this.stats['INBOX.Drafts'] = { ...items[key], orginalKey: this.selectedEmail.draftFolder, hide: (this.selectedEmail.hideFolders || []).includes(key) }
        } else {
          if (!this.stats[key]) {
            this.stats[key] = {
              all: 0, unread: 0, orginalKey: key, hide: (this.selectedEmail.hideFolders || []).includes(key),
            }
          }

          this.$set(this.stats, key, {
            ...items[key], orginalKey: key, hide: !mailboxFolders.find(e => e.folder === key), title: mailboxFolders.find(e => e.folder === key)?.name,
          })
        }
        this.totalEmails.all += Number(items[key]?.all || 0)
        this.totalEmails.unread += Number(items[key]?.unread || 0)
      })
    },
    async getStatistics() {
      this.loadingOverlay = true

      try {
        this.loadStats()

        this.loadingOverlay = false
        this.invalidCredentials = false
        // this.stats = response.data.data
      } catch (err) {
        if (err?.response?.data?.message === 'INVALID_CREDENTIALS') {
          // InvalidMailCredentials
          this.invalidCredentials = true
        }
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    clearData() {
      this.showAssignThreadModal = false
      this.selectedThread = ''
      this.selectedEmails = []
    },
    async downloadAllFiles(files = []) {
      await Promise.all(files.map(file => this.downloadFile(file.token, `${file.name}.${file.extension}`, true)))
      // this.downloadZip(bases)
    },
    downloadFile(fileToken, filename_, download = true) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken, filename: filename_ })
          .then(res => {
            const { data, filename } = res
            const base = `data:${data.type};base64,${data.file}`

            if (download) {
              const a = document.createElement('a')
              a.href = base
              a.setAttribute('target', '_top')
              a.download = filename
              setTimeout(() => {
                a.click()
              }, 50)
            }
            resolve({ base, filename, baseContent: data.file })
          })
          .catch(() => {
            reject()
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

.email-application {
  .mail-read {
    background-color: rgba(var(--theme-color-primary), .1) !important
  }

  .sidebar-content {
    padding: 6px 0 !important;
  }

  .vs__dropdown-toggle {
    border-radius: unset;
    border: unset;
    border-bottom: 1px solid #d8d6de;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .app-fixed-search {
    .form-control:focus:valid,
    .form-control:focus.is-valid {
      box-shadow: none !important;
    }
  }
}
</style>
